@font-face {
  font-family: "Helvetica";
  src:
    url("../../assets/fonts/Helvetica-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src:
    url("../../assets/fonts/Helvetica.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src:
    url("../../assets/fonts/Helvetica-Light.woff2") format("woff2"),
    url("../../assets/fonts/Helvetica-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
