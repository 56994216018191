@mixin tablet {
  @media (min-width: 744px) and (max-width: 1025px) {
    @content;
  }
}

@mixin mdTablet() {
  @media (min-width: 750px) and (max-width: 880px) {
    @content;
  }
}
@mixin xTablet {
  @media (min-width: 750px) and (max-width: 1024px) {
    @content;
  }
}
@mixin phone {
  @media (max-width: 743px) {
    @content;
  }
}
@mixin mdPhone {
  @media (min-width: 410px) and (max-width: 576px) {
    @content;
  }
}

@mixin xs {
  @media (min-width: 0px) and (max-width: 576px) {
    @content;
  }
}

@mixin xxs {
  @media (min-width: 0px) and (max-width: 380px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) and (max-width: 1500px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1441px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1026px) {
    @content;
  }
}

@mixin smHeight {
  @media (max-height: 680px) {
    @content;
  }
}

@mixin mdHeight {
  @media (min-height: 680px) and (max-height: 800px) {
    @content;
  }
}

@mixin bgHeight {
  @media (min-height: 800px) {
    @content;
  }
}

@mixin ipadMini {
  @media (min-width: 760px) and (max-width: 790px) and (min-height: 1000px) and (max-height: 1030px) {
    @content;
  }
}

@mixin zenBook {
  @media (min-width: 850px) and (max-width: 860px) and (min-height: 1250px) and (max-height: 1290px) {
    @content;
  }
}

@mixin galaxyFold {
  @media (max-width: 290px) {
    @content;
  }
}
