@import "main.theme";
@import "fonts";
@import "custom-spacing";
@import "tailwind";

body {
  background: var(--background);
  color: var(--color);
  scroll-behavior: smooth;
  margin: 0;
  font-family:
    "Helvetica",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.w-100 {
  width: 100%;
}
